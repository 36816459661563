import { useSearchParams } from 'next/navigation'
import { isAddress } from 'viem'
import { useAccount } from 'wagmi'
import store from 'store2'
import { useMemo } from 'react'

export const useReferrer = () => {
  const { address: user } = useAccount()
  const searchParams = useSearchParams()

  const referrer = useMemo(() => {
    const referrer = searchParams.get('referrer')?.toLowerCase()

    if (referrer && isAddress(referrer)) {
      return referrer
    }

    return store.get(`referrer-${user}`)
  }, [searchParams, user])

  if (referrer && referrer !== user && isAddress(referrer)) {
    store.set(`referrer-${user}`, referrer)

    return referrer
  }

  return
}
