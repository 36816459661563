import { ChainId } from '@/config/networks'
import { sb712MacroAddress } from 'evm-contracts'
import { Address, parseSignature, TypedDataDomain } from 'viem'
import { useChainId, useSignTypedData } from 'wagmi'

const getDomain = (
  chainId: number,
  verifyingContract: Address
): TypedDataDomain => ({
  name: 'SuperBoring',
  version: '0.0.0',
  chainId: chainId,
  verifyingContract: verifyingContract
})

const types = {
  SetDCAPosition: [
    { name: 'message', type: 'string' },
    { name: 'torex', type: 'address' },
    { name: 'flowRate', type: 'int96' },
    { name: 'distributor', type: 'address' },
    { name: 'referrer', type: 'address' },
    { name: 'upgradeAmount', type: 'uint256' }
  ]
}

export default function useSignFlowUpdate() {
  const { signTypedDataAsync } = useSignTypedData()
  const chainId = useChainId() as ChainId

  return async (message: {
    message: string
    torex: Address
    flowRate: bigint
    distributor?: Address
    referrer?: Address
    upgradeAmount: bigint
  }) => {
    const signature = await signTypedDataAsync({
      domain: getDomain(chainId, sb712MacroAddress[chainId]),
      types,
      primaryType: 'SetDCAPosition',
      message
    })

    return parseSignature(signature)
  }
}
