import { iSuperfluidPoolABI } from 'evm-contracts'
import { useChainId, useReadContracts } from 'wagmi'
import { Address } from 'viem'
import { useMemo } from 'react'
import { TotalReceivedByPool } from '@/types'

export const useTotalAmountReceivedByPoolMember = (
  member?: string | Address,
  pools: string[] | Address[] = []
) => {
  const chainId = useChainId()
  const { data } = useReadContracts({
    query: {
      enabled: Boolean(member && pools.length > 0)
    },
    contracts: [
      ...pools.flatMap((pool) => [
        {
          chainId,
          address: pool as Address,
          abi: iSuperfluidPoolABI,
          functionName: 'getTotalAmountReceivedByMember',
          args: [member as Address]
        }
      ])
    ]
  })

  return useMemo(
    () =>
      pools.reduce<TotalReceivedByPool>((acc, pool, i) => {
        if (data && data[i].status === 'success') {
          acc[pool as Address] = (data[i].result ?? 0) as bigint
        }

        return acc
      }, {}),
    [data, pools]
  )
}
