import { Address, Hash, zeroAddress } from 'viem'
import { useCallback, useState } from 'react'
import { SuperTokenInfo } from '@superfluid-finance/tokenlist'
import {
  useAccount,
  usePublicClient,
  useReadContract,
  useWaitForTransactionReceipt,
  useWriteContract
} from 'wagmi'
import { BLOCK_CONFIRMATIONS } from '@/constants'
import { iSuperTokenABI, ierc20ABI, isethABI } from 'evm-contracts'
import { useTokenRelations } from './useTokenRelations'
import { safeWaitForTransactionReceipt } from '@/hooks/useSafeWaitTransaction'

export const useWrapActions = (superToken?: SuperTokenInfo) => {
  const { address: user } = useAccount()
  const { getUnderlyingTokenOf, isNativeAssetSuperToken } = useTokenRelations()
  const [txHash, setTxHash] = useState<Hash>()
  const client = usePublicClient()

  const { data: rc, isLoading } = useWaitForTransactionReceipt({
    query: {
      enabled: !!txHash
    },
    hash: txHash,
    confirmations: BLOCK_CONFIRMATIONS
  })

  const { writeContractAsync, isPending } = useWriteContract()

  const {
    data: allowance,
    refetch: refetchAllowance,
    isLoading: isAllowanceLoading
  } = useReadContract({
    query: {
      initialData: 0n
    },
    address: getUnderlyingTokenOf(superToken?.address as Address)
      ?.address as Address,
    abi: ierc20ABI,
    functionName: 'allowance',
    args: [user ?? zeroAddress, superToken?.address as Address]
  })

  const approve = useCallback(
    async (amount: bigint) => {
      if (!superToken || !client || !user) return { success: false }

      const underlyingToken = getUnderlyingTokenOf(
        superToken.address as Address
      )

      setTxHash(undefined)

      try {
        const hash = await writeContractAsync({
          address: underlyingToken?.address as Address,
          abi: ierc20ABI,
          functionName: 'approve',
          args: [superToken.address as Address, amount]
        })

        setTxHash(hash)

        const { status } = await safeWaitForTransactionReceipt(client, {
          hash,
          address: user,
          confirmations: BLOCK_CONFIRMATIONS
        })

        await refetchAllowance()

        return { success: status === 'success' }
      } catch (e) {
        console.error(
          new Error(`Failed to approve ${superToken.symbol}`, { cause: e })
        )
      }

      return { success: false }
    },

    [
      superToken,
      user,
      client,
      getUnderlyingTokenOf,
      writeContractAsync,
      refetchAllowance
    ]
  )

  const wrap = useCallback(
    async (amount: bigint) => {
      if (!user || !client || !user) return { success: false }

      setTxHash(undefined)

      try {
        let hash: Hash

        if (isNativeAssetSuperToken(superToken?.address as Address)) {
          hash = await writeContractAsync({
            address: superToken?.address as Address,
            abi: isethABI,
            functionName: 'upgradeByETH',
            value: amount
          })
        } else {
          const underlyingToken = getUnderlyingTokenOf(
            superToken?.address as Address
          )

          const adjustedWrapAmount =
            amount * 10n ** BigInt(18 - (underlyingToken?.decimals ?? 18))

          hash = await writeContractAsync({
            address: superToken?.address as Address,
            abi: iSuperTokenABI,
            functionName: 'upgrade',
            args: [adjustedWrapAmount]
          })
        }

        setTxHash(hash)

        const { status } = await safeWaitForTransactionReceipt(client, {
          hash,
          address: user,
          confirmations: BLOCK_CONFIRMATIONS
        })

        return { success: status === 'success' }
      } catch (e) {
        console.error(new Error('Failed to wrap token'), { cause: e })
      }

      return { success: false }
    },
    [
      user,
      client,
      writeContractAsync,
      superToken,
      isNativeAssetSuperToken,
      getUnderlyingTokenOf
    ]
  )

  const unwrap = useCallback(
    async (amount: bigint, token?: SuperTokenInfo) => {
      if (!user || !client) return { success: false }

      setTxHash(undefined)

      try {
        let hash: Hash

        if (token?.extensions.superTokenInfo.type === 'Native Asset') {
          hash = await writeContractAsync({
            address: token.address as Address,
            abi: isethABI,
            functionName: 'downgradeToETH',
            args: [amount]
          })
        } else {
          const adjustedUnwrapAmount =
            amount * 10n ** BigInt(18 - (token?.decimals ?? 18))
          hash = await writeContractAsync({
            address: token?.address as Address,
            abi: iSuperTokenABI,
            functionName: 'downgrade',
            args: [adjustedUnwrapAmount]
          })
        }

        setTxHash(hash)

        const { status } = await safeWaitForTransactionReceipt(client, {
          hash,
          address: user,
          confirmations: BLOCK_CONFIRMATIONS
        })

        return { success: status === 'success' }
      } catch (e) {
        console.error(new Error('Failed to downgrade token'), { cause: e })
      }

      return { success: false }
    },
    [user, client, writeContractAsync]
  )

  return {
    approve,
    allowance,
    wrap,
    unwrap,
    rc,
    isLoading: isLoading || isPending || isAllowanceLoading
  }
}
