import { cn } from "@/utils";
import { FC, forwardRef, useCallback, useRef, useState } from "react";

interface StepConf {
    title: string;
}

interface StepProps {
    step: StepConf;
    active?: boolean;
    className?: string;
}

const Step = forwardRef<HTMLDivElement, StepProps>(
    ({ step, active, className }, ref) => {
        return (
            <div
                data-testid="step"
                ref={ref}
                className={cn(
                    "flex flex-col items-center gap-2 relative",
                    className
                )}
            >
                <div
                    data-testid="step-title"
                    className={cn(
                        "text-sm transition-all",
                        active ? "text-white" : "text-gray-400"
                    )}
                >
                    {step.title}
                </div>
                <div
                    className={cn(
                        "w-2 h-2 rounded-full z-10 transition-all",
                        active && "bg-brand-main shadow-stepper-active",
                        !active && "bg-gray-500"
                    )}
                />
            </div>
        );
    }
);
Step.displayName = "Step";

interface StepperProps {
    steps: StepConf[];
    activeStep?: number;
    className?: string;
}

const Stepper: FC<StepperProps> = ({ activeStep = 0, steps, className }) => {
    const stepRefs = useRef<(HTMLDivElement | null)[]>([]);
    const [stepWidths, setStepWidths] = useState<number[]>([]);

    const changeStepRef = useCallback(
        (el: HTMLDivElement | null, index: number) => {
            stepRefs.current[index] = el;
            if (el && stepWidths[index] !== el.clientWidth) {
                setStepWidths((prev) => {
                    const newWidths = [...prev];
                    newWidths[index] = el.clientWidth;
                    return newWidths;
                });
            }
        },
        [stepWidths]
    );

    const getStepRefCallback = useCallback(
        (index: number) => (el: HTMLDivElement | null) =>
            changeStepRef(el, index),
        [changeStepRef]
    );

    const progressWidth = 100 / (steps.length - 1);

    const stop1 = activeStep * progressWidth;
    const stop2 = (activeStep + 1) * progressWidth;

    return (
        <div data-testid="stepper" className={cn(className)}>
            <div className="flex justify-between">
                {steps.map((step, index) => (
                    <Step
                        key={index}
                        ref={getStepRefCallback(index)}
                        active={index <= activeStep}
                        step={step}
                    />
                ))}
            </div>

            {stepWidths.length === steps.length && (
                <div
                    className="-mt-[5px]"
                    style={{
                        paddingLeft: stepWidths[0] / 2,
                        paddingRight: stepWidths[stepWidths.length - 1] / 2,
                    }}
                >
                    <div className="h-[2px] bg-gray-500 w-full" />
                    <div
                        className="h-[2px] -mt-[2px] transition-all"
                        style={{
                            background: `linear-gradient(to right, var(--brand-main), var(--brand-main) ${stop1}%, transparent ${stop2}%, transparent)`,
                        }}
                    />
                </div>
            )}
        </div>
    );
};

export default Stepper;
