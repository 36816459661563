import { useAccount, usePublicClient, useWriteContract } from 'wagmi'
import { useCallback } from 'react'
import { BLOCK_CONFIRMATIONS, EMPTY_BYTES } from '@/constants'
import { Address } from 'viem'
import { readContract } from 'viem/actions'
import { ChainId } from '@/config/networks'
import { cfAv1ForwarderABI, cfAv1ForwarderAddress } from 'evm-contracts'
import { safeWaitForTransactionReceipt } from '@/hooks/useSafeWaitTransaction'

export const useStreamActions = () => {
  const { address: sender, chainId } = useAccount()
  const client = usePublicClient()

  const { writeContractAsync } = useWriteContract()

  const flowExists = useCallback(
    async (superToken: Address, receiver: Address) => {
      if (!sender || !client) return 0n

      const [_, flowrate] = await readContract(client, {
        address: cfAv1ForwarderAddress[chainId as ChainId],
        abi: cfAv1ForwarderABI,
        functionName: 'getFlowInfo',
        args: [superToken, sender, receiver]
      })

      return flowrate
    },

    [sender, client, chainId]
  )

  const deleteFlow = useCallback(
    async (superToken: Address, receiver: Address) => {
      if (!sender || !client) return

      const hash = await writeContractAsync({
        address: cfAv1ForwarderAddress[chainId as ChainId],
        abi: cfAv1ForwarderABI,
        functionName: 'deleteFlow',
        args: [superToken, sender, receiver, EMPTY_BYTES]
      })

      const rc = await safeWaitForTransactionReceipt(client, {
        hash,
        address: sender,
        confirmations: BLOCK_CONFIRMATIONS
      })

      return rc
    },
    [sender, client, writeContractAsync, chainId]
  )

  return {
    deleteFlow,
    flowExists
  }
}
