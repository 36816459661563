import { useSearchParams } from 'next/navigation'
import { isAddress } from 'viem'
import { useMemo } from 'react'
import { useTorexTokens } from './useTorexTokens'

export const useTorexQueryParams = () => {
  const searchParams = useSearchParams()
  const { inboundTokens, outboundTokens } = useTorexTokens()

  const inToken = useMemo(() => {
    const inToken = searchParams.get('in')?.toLowerCase()

    if (inToken) {
      return isAddress(inToken)
        ? inboundTokens.find((token) => token.address.toLowerCase() === inToken)
        : inboundTokens.find((token) => token.symbol.toLowerCase() === inToken)
    }
  }, [searchParams, inboundTokens])

  const outToken = useMemo(() => {
    const inToken = searchParams.get('out')?.toLowerCase()

    if (inToken) {
      return isAddress(inToken)
        ? outboundTokens.find(
            (token) => token.address.toLowerCase() === inToken
          )
        : outboundTokens.find((token) => token.symbol.toLowerCase() === inToken)
    }
  }, [searchParams, outboundTokens])

  return {
    inToken,
    outToken
  }
}
