import { useCallback } from 'react'
import { useStaking } from './useStaking'
import { FlowRate, Torex } from '@/types'
import { formatEther } from 'viem'
import { getFlowRatePerSecond } from '@/utils'
import { defaultFlowRate } from '@/constants'

export const usePoolEstimationFns = () => {
  const { torexesWithStaking } = useStaking()

  const estimateUserRewardFlowrate = useCallback(
    (torex?: Torex, flowRate?: FlowRate) => {
      if (!torex || !flowRate || !torexesWithStaking[torex.address]) {
        return defaultFlowRate
      }

      const perSecondFlowRate = getFlowRatePerSecond(flowRate)
      const torexWithStaking = torexesWithStaking[torex.address]
      const totalVolumeEth = Number(
        formatEther(torexWithStaking.volume + perSecondFlowRate)
      )

      const sharePercentage = Number(flowRate.amountEth) / totalVolumeEth
      const totalRewardFlowrateEth = Number(
        formatEther(torexWithStaking.totalRewardFlowrate)
      )

      return {
        amountEth: (totalRewardFlowrateEth * sharePercentage).toString(),
        period: flowRate.period
      }
    },
    [torexesWithStaking]
  )

  return {
    estimateUserRewardFlowrate
  }
}
