'use client'

import { ChainId } from '@/config/networks'
import { getBuiltGraphSdkFor } from '@/subgraph'
import { useQuery } from '@tanstack/react-query'
import { averageDiffTimestamps } from '@/utils'
import { millisecondsToMinutes } from 'date-fns'
import { useChainId } from 'wagmi'
import { useMemo } from 'react'
import { Torex } from '@/types'

export const useAvgLMEFrequency = (torex?: Torex) => {
  const userChainId = useChainId() as ChainId
  const { LiquidityMovements } = getBuiltGraphSdkFor(userChainId)

  const { data: liquidityMovements } = useQuery({
    enabled: !!torex,
    queryKey: ['liquidity-movements'],
    select: (data) => data.events.map((event) => Number(event.timestamp)),
    queryFn: async () =>
      LiquidityMovements({
        poolId: torex!.distribuitionPool,
        timestamp_gte: '0',
        first: 100
      })
  })

  return useMemo(
    () => millisecondsToMinutes(averageDiffTimestamps(liquidityMovements)),
    [liquidityMovements]
  )
}
