"use client";

import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useAccount, useChainId, useReadContract } from "wagmi";
import { Button } from "./ui/button";

import { ChainId, supportedChainsById } from "@/config/networks";
import { TokenInput } from "./TokenInput";
import { FlowRate, TokenConfig } from "@/types";
import { SuperTokenInfo, TokenInfo } from "@superfluid-finance/tokenlist";
import {
    calculateAmountToWrap,
    calculateStreamEnds,
    calculateTotalBalance,
    cn,
    getFlowRatePerSecond,
    getOriginalFlowRate,
    getStreamedAmountForPeriod,
    toFixedFromString,
} from "@/utils";
import { Icons } from "./ui/icons";
import { useTorex } from "@/hooks/useTorex";
import { useTorexTokens } from "@/hooks/useTorexTokens";
import { useReferrer } from "@/hooks/useReferrer";
import {
    Accordion,
    AccordionContent,
    AccordionItem,
    AccordionTrigger,
} from "./ui/accordion";
import { useTranslation } from "@/app/i18n/client";

import { CardSkeleton } from "./CardSkeleton";
import { Success } from "./Success";
import { useRouter } from "next/navigation";
import { PROTOCOL_FEE_MULTIPLIER } from "@/constants";
import { TokenIcon } from "./TokenIcon";
import { useWeb3Modal } from "@web3modal/wagmi/react";
import { Address, formatEther, formatUnits, zeroAddress } from "viem";
import { useRewardToken } from "@/hooks/useRewardToken";
import { useAvgLMEFrequency } from "@/hooks/useAvgLMEFrequency";
import { cfAv1ForwarderABI, cfAv1ForwarderAddress } from "evm-contracts";
import { usePoolEstimationFns } from "@/hooks/usePoolEstimationFns";
import { useWrapActions } from "@/hooks/useWrapActions";
import { useBalances } from "@/hooks/useBalances";
import { FlowingBalance } from "./FlowingBalance";
import { formatDuration } from "date-fns";
import { Switch } from "./ui/switch";
import { Label } from "./ui/label";
import { HSeparator } from "./Separator";
import { useTokenRelations } from "@/hooks/useTokenRelations";
import { CustomPopover } from "./CustomPopover";
import { usePortfolio } from "@/hooks/usePortfolio";
import { useTorexQueryParams } from "@/hooks/useTorexQueryParams";
import { useCopyToClipboard } from "react-use";
import Stepper from "./Stepper";

const defaultFlowRate: FlowRate = {
    amountEth: "",
    period: "month",
};

type DCAWidgetProps = {
    defaultInboundToken?: TokenConfig;
    defaultOutboundToken?: TokenConfig;
    onClose?: () => void;
    mode?: "landing" | "default";
};

export const DCAWidget: FC<DCAWidgetProps> = ({
    defaultInboundToken,
    defaultOutboundToken,
    onClose,
    mode = "default",
}) => {
    const { address: user, chain, isConnected } = useAccount();

    const { t } = useTranslation(["dca-widget", "common"]);

    const router = useRouter();
    const userChainId = useChainId() as ChainId;
    const [isSuccess, setSuccess] = useState(false);
    const isChainSupported = useMemo(
        () => Boolean(supportedChainsById[chain?.id as ChainId]),
        [chain]
    );
    const { getUnderlyingTokenOf } = useTokenRelations();

    const { open } = useWeb3Modal();

    const [inboundToken, setInboundToken] = useState<
        TokenConfig & {
            underlying?: TokenInfo;
        }
    >({
        token: defaultInboundToken?.token,
        underlying: getUnderlyingTokenOf(defaultInboundToken?.token?.address),
        flowRate: defaultInboundToken?.flowRate ?? defaultFlowRate,
    });

    const [skipWrapping, setSkipWrapping] = useState(false);

    const [outboundToken, setOutBoundToken] = useState<TokenConfig>({
        token: defaultOutboundToken?.token,
    });

    const { isNativeAssetSuperToken } = useTokenRelations();
    const tokensSelected = useMemo(
        () => Boolean(inboundToken.token && outboundToken.token),
        [inboundToken, outboundToken]
    );

    const { refetchPortfolio } = usePortfolio();

    const flowRateSelected = useMemo(
        () =>
            inboundToken.flowRate?.amountEth !== "" &&
            Number(inboundToken.flowRate?.amountEth) > 0,
        [inboundToken]
    );

    const {
        dca,
        isLoading: isDCALoading,
        hasInverse,
        existingFlowRate,
        benchmarkQuote,
        torex,
    } = useTorex({
        user,
        inboundToken: inboundToken.token?.address,
        outboundToken: outboundToken.token?.address,
    });

    const avgLMEFrequency = useAvgLMEFrequency(torex);
    const { token: rewardToken } = useRewardToken();
    const { estimateUserRewardFlowrate } = usePoolEstimationFns();

    const { data: netFlow } = useReadContract({
        address: cfAv1ForwarderAddress[userChainId],
        abi: cfAv1ForwarderABI,
        functionName: "getAccountFlowrate",
        args: [inboundToken.token?.address as Address, user ?? zeroAddress],
    });

    const referrer = useReferrer();

    const {
        approve,
        wrap,
        allowance,
        isLoading: isWrapActionLoading,
    } = useWrapActions(inboundToken.token);

    const { balances, refetchBalances } = useBalances();

    const { inboundBalance, outboundBalance } = useMemo(() => {
        const inboundBalance = balances.find(
            (b) => b.token.address === inboundToken.token?.address
        );
        const outboundBalance = balances.find(
            (b) => b.token.address === outboundToken.token?.address
        );

        return {
            inboundBalance,
            outboundBalance,
        };
    }, [balances, inboundToken, outboundToken]);

    const selectToken = useCallback(
        (type: "in" | "out") => (token: SuperTokenInfo) => {
            switch (type) {
                case "in": {
                    setInboundToken({
                        flowRate: inboundToken.flowRate,
                        token,
                        underlying: getUnderlyingTokenOf(token.address),
                    });

                    if (
                        token.address === outboundToken.token?.address ||
                        (inboundToken.token && outboundToken.token)
                    ) {
                        setOutBoundToken({ flowRate: defaultFlowRate });
                    }

                    break;
                }
                case "out": {
                    setOutBoundToken({
                        flowRate: outboundToken.flowRate,
                        token,
                    });

                    if (
                        token.address === inboundToken.token?.address ||
                        (inboundToken.token && outboundToken.token)
                    ) {
                        setInboundToken({ flowRate: defaultFlowRate });
                    }

                    break;
                }
            }
        },
        [inboundToken, outboundToken, getUnderlyingTokenOf]
    );

    const streamOverDuration = useMemo(
        () => ({
            day: getStreamedAmountForPeriod(inboundToken.flowRate, "day"),
            period: getStreamedAmountForPeriod(inboundToken.flowRate),
        }),
        [inboundToken]
    );

    const switchTokens = useCallback(() => {
        setInboundToken({
            ...outboundToken,
            flowRate: inboundToken.flowRate,
            underlying: getUnderlyingTokenOf(outboundToken.token?.address),
        });
        setOutBoundToken({
            token: inboundToken.token,
        });
    }, [inboundToken, outboundToken, getUnderlyingTokenOf]);

    const onAmountEthChange = useCallback(
        (amountEth: string) => {
            setInboundToken({
                ...inboundToken,
                flowRate: {
                    amountEth,
                    period: inboundToken.flowRate?.period ?? "month",
                },
                underlying: getUnderlyingTokenOf(inboundToken.token?.address),
            });
        },
        [inboundToken, getUnderlyingTokenOf]
    );

    // biome-ignore lint/correctness/useExhaustiveDependencies: We don't need to rerun this when the tokens change.
    useEffect(() => {
        if (
            userChainId === undefined &&
            !inboundToken.token &&
            !outboundToken.token
        )
            return;

        setInboundToken({
            token: defaultInboundToken?.token,
            flowRate: defaultFlowRate,
            underlying: getUnderlyingTokenOf(
                defaultInboundToken?.token?.address
            ),
        });
        setOutBoundToken({
            token: defaultOutboundToken?.token,
        });
    }, [userChainId, getUnderlyingTokenOf]);

    const { inboundTokens, outboundTokens } = useTorexTokens({
        selectedInboundToken: inboundToken.token,
        selectedOutboundToken: outboundToken.token,
    });

    const amountToWrap = useMemo(
        () =>
            calculateAmountToWrap({
                flowRate: inboundToken.flowRate,
                superTokenBalance: inboundBalance?.token.balance,
                underlyingBalance: inboundBalance?.underlying?.balance,
                underlyingDecimals: inboundBalance?.underlying?.decimals,
                existingFlowRate: existingFlowRate,
            }),

        [inboundToken, inboundBalance, existingFlowRate]
    );

    const isApprovalNeeded = useMemo(
        () =>
            skipWrapping || isNativeAssetSuperToken(inboundToken.token?.address)
                ? false
                : (allowance ?? 0n) < amountToWrap,
        [
            allowance,
            skipWrapping,
            inboundToken,
            amountToWrap,
            isNativeAssetSuperToken,
        ]
    );

     // TODO: Remove this when the MACRO supports native token upgrades
    const isWrapNeeded = useMemo(
        () =>
            isNativeAssetSuperToken(inboundToken.token?.address) &&
            amountToWrap > 0,
        [isNativeAssetSuperToken, amountToWrap, inboundToken]
    );

    const cta = useMemo(() => {
        if (!isConnected) {
            return {
                action: () => open(),
                text: t("common:connect.wallet"),
                disabled: false,
            };
        }

        if (!isChainSupported) {
            return {
                action: () => open({ view: "Networks" }),
                text: t("cta.switchNetwork"),
                disabled: false,
            };
        }

        if (!tokensSelected) {
            return {
                action: () => {},
                text: t("cta.selectTokens"),
                disabled: true,
            };
        }

        if (!flowRateSelected) {
            return {
                action: () => {},
                text: t("cta.setFlowrate"),
                disabled: true,
            };
        }

        if (isDCALoading || isWrapActionLoading) {
            return {
                action: () => {},
                text: "",
                disabled: true,
            };
        }

        if (isApprovalNeeded) {
            return {
                action: async () => {
                    await approve((amountToWrap * 120n) / 100n);
                },
                text: t("cta.approveWrap"),
                disabled: false,
            };
        }


        if (isWrapNeeded) {
            return {
                action: async () => {
                    await wrap(amountToWrap);

                    refetchBalances();
                },
                text: t("cta.wrap"),
                disabled: false,
            }
        }

        return {
            action: async () => {
                const { success } = await dca({
                    flowRate: inboundToken.flowRate,
                    referrer,
                    amountToWrap
                });

                if (success) {
                    setSuccess(true);

                    await refetchPortfolio();
                    refetchBalances();
                }
            },
            text:
                existingFlowRate > 0n ? t("cta.updateDCA") : t("cta.startDCA"),
            disabled: false,
        };
    }, [
        t,
        inboundToken,
        existingFlowRate,
        refetchPortfolio,
        refetchBalances,
        open,
        referrer,
        approve,
        wrap,
        isWrapNeeded,
        amountToWrap,
        dca,
        isConnected,
        isChainSupported,
        tokensSelected,
        flowRateSelected,
        isDCALoading,
        isWrapActionLoading,
        isApprovalNeeded,
    ]);

    const error = useMemo(() => {
        if (!isConnected) {
            return null;
        }

        if (Number(inboundToken.flowRate?.amountEth) < 0) {
            return {
                msg: t("error.negativeFlow"),
                type: "NEGATIVE_FLOW" as const,
            };
        }

        const fps = getFlowRatePerSecond(inboundToken.flowRate);

        if (fps > 0n && fps < (torex?.minimumInTokenFlowRate ?? 0n) * -1n) {
            return {
                msg: t("error.lessThanMinimumFlowrate"),
                type: "LESS_THAN_MIN" as const,
            };
        }

        if (
            calculateTotalBalance(inboundBalance, "super") <
            streamOverDuration.day
        ) {
            return {
                msg: t("error.lessThan24h"),
                type: "LESS_THAN_24H" as const,
            };
        }

        return null;
    }, [inboundToken, inboundBalance, streamOverDuration, t, isConnected, torex?.minimumInTokenFlowRate]);

    const warningMsg = useMemo(() => {
        if (
            streamOverDuration.period > (inboundBalance?.token.balance ?? 0n) &&
            skipWrapping
        ) {
            return t("warning.lessThanAMonth");
        }

        return null;
    }, [streamOverDuration, inboundBalance, skipWrapping, t]);

    const { inToken: queryInToken, outToken: queryOutToken } =
        useTorexQueryParams();

    useEffect(() => {
        if (queryInToken && queryOutToken) {
            setInboundToken((prev) => ({
                ...prev,
                token: queryInToken,
                underlying: getUnderlyingTokenOf(queryInToken.address),
            }));
            setOutBoundToken((prev) => ({
                ...prev,
                token: queryOutToken,
            }));
        }
    }, [queryInToken, queryOutToken, getUnderlyingTokenOf]);

    const torexURL = useMemo(() => {
        if (inboundToken.token && outboundToken.token) {
            return `${location.protocol}//${location.hostname}${
                !location.port ? "" : `:${location.port}`
            }/?in=${inboundToken.token.symbol}&out=${
                outboundToken.token.symbol
            }`;
        }

        return null;
    }, [inboundToken, outboundToken]);

    const [_, copyToClipBoard] = useCopyToClipboard();
    const [copied, setCopied] = useState(false);

    const activeStep = useMemo(() => {
        if (
            !tokensSelected ||
            !flowRateSelected ||
            !isConnected ||
            !isChainSupported ||
            isApprovalNeeded ||
            isWrapNeeded
        ) {
            return 0;
        }
        return 1;
    }, [
        isConnected,
        isChainSupported,
        tokensSelected,
        flowRateSelected,
        isApprovalNeeded,
        isWrapNeeded
    ]);

    const steps = useMemo(() => {
        const steps = []

        if (isApprovalNeeded) {
            steps.push({ title: "Approve" })
        }
        if (isWrapNeeded) {
            steps.push({ title: "Wrap" })
        }

        steps.push({ title: existingFlowRate ? "Update Position" : "Start DCA" })

        return steps
    }, [
        existingFlowRate,
        isApprovalNeeded,
        isWrapNeeded,
    ])

    return isSuccess ? (
        <Success
            text={{
                title: t("dca-widget:success.title"),
                description: t("dca-widget:success.description"),
                ctaText: t("dca-widget:success.cta"),
                accordionTrigger: t("dca-widget:success.details.title"),
            }}
            videoOverlay={
                <div className="flex flex-col py-6 z-10 items-center bg-black bg-opacity-60 backdrop-blur-sm rounded-xl border-[0.5px] border-white overflow-y-scroll scroll-ml-10">
                    <p className="font-light text-lg">
                        {t("dca-widget:success.videoOverlayTitle")}
                    </p>
                    <div className="flex justify-center min-w-[200px] gap-1 items-baseline font-semibold text-brand-main">
                        <p className="text-2xl text-brand-main uppercase font-mono">
                            <FlowingBalance
                                cutAtSignificantFlowingDecimal
                                balance={0n}
                                flowRate={getFlowRatePerSecond(
                                    inboundToken.flowRate
                                )}
                            />
                        </p>
                        <p className="text-sm">{inboundToken.token?.symbol}</p>
                    </div>
                </div>
            }
            accordionContent={
                <div className="flex flex-col gap-2">
                    <div
                        className="flex justify-between"
                        data-testid="success-flow-rate"
                    >
                        <p className="text-gray-400 font-light">
                            {t("dca-widget:success.details.rows.1")}
                        </p>
                        <p>{`${inboundToken.flowRate?.amountEth} ${inboundToken.token?.symbol} / ${inboundToken.flowRate?.period}`}</p>
                    </div>
                    <div
                        className="flex justify-between"
                        data-testid="success-market"
                    >
                        <p className="text-gray-400 font-light">
                            {t("dca-widget:success.details.rows.2")}
                        </p>
                        <div className="flex gap-1">
                            <div className="flex gap-1">
                                {inboundToken.token && (
                                    <TokenIcon token={inboundToken.token} />
                                )}
                                <p data-testid="success-market-inbound-token">
                                    {inboundToken.token?.symbol}
                                </p>
                            </div>
                            /
                            <div className="flex gap-1">
                                {outboundToken.token && (
                                    <TokenIcon token={outboundToken.token} />
                                )}
                                <p data-testid="success-market-outbound-token">
                                    {outboundToken.token?.symbol}
                                </p>
                            </div>
                        </div>
                    </div>

                    <div
                        className="flex justify-between"
                        data-testid="success-fee"
                    >
                        <p className="text-gray-400 font-light">
                            {t("dca-widget:success.details.rows.3")}
                        </p>
                        <p>{`${
                            Number(inboundToken.flowRate?.amountEth) *
                            PROTOCOL_FEE_MULTIPLIER
                        } ${inboundToken.token?.symbol} / ${
                            inboundToken.flowRate?.period
                        }`}</p>
                    </div>
                    <div
                        className="flex justify-between"
                        data-testid="success-avg-payout-time"
                    >
                        <p className="text-gray-400 font-light">
                            {t("dca-widget:success.details.rows.4")}
                        </p>
                        <p>
                            {avgLMEFrequency > 0
                                ? `~${formatDuration({
                                      minutes: avgLMEFrequency,
                                  })}`
                                : "--"}
                        </p>
                    </div>
                </div>
            }
            onBack={() =>
                onClose?.() ?? router.push("/portfolio", { scroll: false })
            }
        />
    ) : (
        <CardSkeleton
            title={
                mode === "default" && (
                    <div className="w-full flex justify-between items-center">
                        {t("title")}
                        {torexURL && (
                            <CustomPopover
                                trigger={
                                    <Button
                                        data-testid="invite-link-copy-clipboard-button"
                                        size="sm"
                                        variant="ghost"
                                        onClick={() => {
                                            if (!copied) {
                                                copyToClipBoard(torexURL);
                                                setCopied(true);
                                                setTimeout(() => {
                                                    setCopied(false);
                                                }, 3000);
                                            }
                                        }}
                                        className={cn(
                                            "h-6 p-1",
                                            !copied && "hover:bg-gray-500"
                                        )}
                                    >
                                        {copied ? (
                                            <Icons.check
                                                size={16}
                                                className="animate-fade text-brand-main w-full"
                                            />
                                        ) : (
                                            <Icons.copy
                                                size={16}
                                                className="animate-fade w-full"
                                            />
                                        )}
                                    </Button>
                                }
                                content={
                                    <p className="text-gray-400 text-xs tracking-widest">
                                        {t("copyConfig")}
                                    </p>
                                }
                            />
                        )}
                    </div>
                )
            }
            description={mode === "default" && t("description")}
            error={error?.msg}
            warning={warningMsg}
            cta={
                <Button
                    data-testid="swap-button"
                    disabled={cta.disabled || error !== null}
                    loading={isDCALoading || isWrapActionLoading}
                    variant="brand"
                    size="lg"
                    className="rounded-lg w-full"
                    onClick={cta.action}
                >
                    {cta.text}
                </Button>
            }
        >
            <div
                data-testid="dca-widget"
                className="flex flex-col gap-1 relative"
            >
                <TokenInput
                    dataTestId="in-token-selection"
                    token={inboundToken.token}
                    flowRate={inboundToken.flowRate}
                    balance={calculateTotalBalance(inboundBalance, "super")}
                    quickSetMode="matchInflow"
                    selectedNetwork={supportedChainsById[userChainId]}
                    tokens={inboundTokens}
                    title={t("inputs.intoken")}
                    netFlow={netFlow}
                    onTokenSelect={selectToken("in")}
                    onAmountChange={onAmountEthChange}
                    className="h-28"
                    displayBalanceMode="mixed"
                    displaySelectedTokenMode="underlying"
                />
                <button
                    type="button"
                    disabled={!hasInverse}
                    title={
                        hasInverse ? "Switch tokens" : "No inverse TOREX found"
                    }
                    className={cn(
                        "absolute border-2 border-gray-600 bg-black left-[calc(50%-20px)] top-24 p-2 rounded-xl hover:bg-brand-dark",
                        !hasInverse && "  cursor-not-allowed"
                    )}
                    onClick={switchTokens}
                >
                    {hasInverse ? (
                        <Icons.swapBidirectional className="-rotate-90" />
                    ) : (
                        <Icons.swapUnidirectinal />
                    )}
                </button>
                <TokenInput
                    dataTestId="out-token-selection"
                    token={outboundToken.token}
                    flowRate={inboundToken.flowRate}
                    balance={outboundBalance?.token.balance ?? 0n}
                    quickSetMode="none"
                    amount={"1"}
                    disabled
                    tokens={outboundTokens}
                    title={t("inputs.outtoken")}
                    selectedNetwork={supportedChainsById[userChainId]}
                    onTokenSelect={selectToken("out")}
                    onAmountChange={onAmountEthChange}
                    displaySelectedTokenMode="underlying"
                />

                <div className="bg-black px-4 rounded-lg gap-2 mt-1.5">
                    <Accordion
                        data-testid="details-accordion"
                        type="single"
                        collapsible
                        defaultValue="item-1"
                    >
                        <AccordionItem
                            className="border-none"
                            value={mode === "default" ? "item-1" : "none"}
                        >
                            <AccordionTrigger>
                                <p className="text-gray-200 text-xs font-semibold uppercase tracking-widest">
                                    {tokensSelected
                                        ? t("details.exchangeRate", {
                                              symbol1:
                                                  inboundToken.token?.symbol,
                                              benchmarkQuote:
                                                  toFixedFromString(
                                                      benchmarkQuote
                                                  ),
                                              symbol2:
                                                  outboundToken.token?.symbol,
                                          })
                                        : t("details.title")}
                                </p>
                            </AccordionTrigger>
                            <AccordionContent className="flex flex-col gap-1">
                                <div className="flex justify-between">
                                    <CustomPopover
                                        trigger={
                                            <div
                                                className={cn(
                                                    "flex items-center gap-1 rounded-md cursor-pointer",
                                                    error?.type ===
                                                        "LESS_THAN_MIN"
                                                        ? "text-red-error"
                                                        : "text-gray-400"
                                                )}
                                            >
                                                <p
                                                    data-testid="min-flowrate-text"
                                                    className="text-xs tracking-widest"
                                                >
                                                    {t(
                                                        "details.minimumFlowrate.title"
                                                    )}
                                                </p>
                                                <Icons.info
                                                    data-testid="min-flowrate-tooltip"
                                                    className={
                                                        error?.type ===
                                                        "LESS_THAN_MIN"
                                                            ? "fill-red-error"
                                                            : "fill-gray-400"
                                                    }
                                                    size={12}
                                                />
                                            </div>
                                        }
                                        content={
                                            <p
                                                data-testid="min-flowrate-tooltip-content"
                                                className="text-gray-400 text-xs tracking-widest"
                                            >
                                                {t(
                                                    "details.minimumFlowrate.tooltip",
                                                    {
                                                        inbound:
                                                            inboundToken.token
                                                                ?.symbol ??
                                                            "Inbound Tokens",
                                                        outbound:
                                                            outboundToken.token
                                                                ?.symbol ??
                                                            "Outbound Tokens",
                                                        reward: rewardToken.symbol,
                                                    }
                                                )}
                                            </p>
                                        }
                                    />

                                    <p
                                        data-testid="min-flowrate-value"
                                        className={cn(
                                            "text-xs",
                                            error?.type === "LESS_THAN_MIN"
                                                ? "text-red-error"
                                                : "text-gray-400"
                                        )}
                                    >
                                        {torex
                                            ? `${toFixedFromString(
                                                  formatEther(
                                                      getStreamedAmountForPeriod(
                                                          getOriginalFlowRate(
                                                              torex.minimumInTokenFlowRate *
                                                                  -1n,
                                                              "month"
                                                          )
                                                      )
                                                  )
                                              )} ${torex.inboundToken.symbol}`
                                            : "--"}
                                    </p>
                                </div>
                                <HSeparator className="my-4" />
                                <div className="flex justify-between">
                                    <CustomPopover
                                        trigger={
                                            <div className="flex items-center gap-1 rounded-md cursor-pointer">
                                                <p
                                                    data-testid="available-underlying-balance-text"
                                                    className="text-gray-400 text-xs tracking-widest"
                                                >
                                                    {t(
                                                        "details.availableBalance.title",
                                                        {
                                                            symbol: inboundToken
                                                                ?.underlying
                                                                ?.symbol,
                                                        }
                                                    )}
                                                </p>
                                                <Icons.info
                                                    data-testid="available-underlying-balance-tooltip"
                                                    className="fill-gray-400"
                                                    size={12}
                                                />
                                            </div>
                                        }
                                        content={
                                            <p
                                                data-testid="available-underlying-balance-tooltip-content"
                                                className="text-gray-400 text-xs tracking-widest"
                                            >
                                                {t(
                                                    "details.availableBalance.tooltip",
                                                    {
                                                        symbol:
                                                            inboundToken
                                                                ?.underlying
                                                                ?.symbol ??
                                                            "Underlying Token",
                                                    }
                                                )}
                                            </p>
                                        }
                                    />

                                    <p
                                        data-testid="available-underlying-balance-value"
                                        className="text-xs text-gray-400"
                                    >
                                        {toFixedFromString(
                                            formatUnits(
                                                inboundBalance?.underlying
                                                    ?.balance ?? 0n,
                                                inboundBalance?.underlying
                                                    ?.decimals ?? 18
                                            )
                                        )}
                                    </p>
                                </div>
                                <div className="flex justify-between">
                                    <CustomPopover
                                        trigger={
                                            <div className="flex items-center gap-1 rounded-md cursor-pointer">
                                                <p
                                                    data-testid="available-super-token-balance-text"
                                                    className="text-gray-400 text-xs tracking-widest"
                                                >
                                                    {t(
                                                        "details.availableSuperBalance.title",
                                                        {
                                                            symbol: inboundToken
                                                                .token?.symbol,
                                                        }
                                                    )}
                                                </p>
                                                <Icons.info
                                                    data-testid="available-super-token-balance-tooltip"
                                                    className="fill-gray-400"
                                                    size={12}
                                                />
                                            </div>
                                        }
                                        content={
                                            <p
                                                data-testid="available-super-token-balance-tooltip-content"
                                                className="text-gray-400 text-xs tracking-widest"
                                            >
                                                {t(
                                                    "details.availableSuperBalance.tooltip",
                                                    {
                                                        symbol: inboundToken
                                                            .token?.symbol,
                                                    }
                                                )}
                                            </p>
                                        }
                                    />

                                    <p
                                        data-testid="available-super-token-balance-value"
                                        className="text-xs text-gray-400"
                                    >
                                        {toFixedFromString(
                                            formatUnits(
                                                inboundBalance?.token
                                                    ?.balance ?? 0n,
                                                inboundBalance?.token
                                                    ?.decimals ?? 18
                                            )
                                        )}
                                    </p>
                                </div>
                                <div className="flex justify-between">
                                    <CustomPopover
                                        trigger={
                                            <div className="flex items-center gap-1 rounded-md cursor-pointer">
                                                <p
                                                    data-testid="wrap-amount-text"
                                                    className="text-gray-400 text-xs tracking-widest"
                                                >
                                                    {t(
                                                        "details.wrapAmount.title"
                                                    )}
                                                </p>
                                                <Icons.info
                                                    data-testid="wrap-amount-tooltip"
                                                    className="fill-gray-400"
                                                    size={12}
                                                />
                                            </div>
                                        }
                                        content={
                                            <p
                                                data-testid="wrap-amount-tooltip-content"
                                                className="text-gray-400 text-xs tracking-widest"
                                            >
                                                {t(
                                                    "details.wrapAmount.tooltip",
                                                    {
                                                        symbol:
                                                            inboundBalance
                                                                ?.underlying
                                                                ?.symbol ??
                                                            "Underlying Token",
                                                    }
                                                )}
                                            </p>
                                        }
                                    />

                                    <p
                                        data-testid="wrap-amount-value"
                                        className="text-xs text-gray-400"
                                    >
                                        {amountToWrap > 0n && !skipWrapping
                                            ? toFixedFromString(
                                                  formatUnits(
                                                      amountToWrap,
                                                      inboundBalance?.underlying
                                                          ?.decimals ?? 18
                                                  )
                                              )
                                            : "--"}
                                    </p>
                                </div>

                                <HSeparator className="my-4" />

                                <div className="flex justify-between">
                                    <CustomPopover
                                        trigger={
                                            <div className="flex items-center gap-1 rounded-md cursor-pointer">
                                                <p
                                                    data-testid="fee-text"
                                                    className="text-gray-400 text-xs tracking-widest"
                                                >
                                                    {t("details.fee.title")}
                                                </p>
                                                <Icons.info
                                                    data-testid="fee-tooltip"
                                                    className="fill-gray-400"
                                                    size={12}
                                                />
                                            </div>
                                        }
                                        content={
                                            <p
                                                data-testid="fee-tooltip-content"
                                                className="text-gray-400 text-xs tracking-widest"
                                            >
                                                {t("details.fee.tooltip")}
                                            </p>
                                        }
                                    />

                                    <p
                                        data-testid="fee-value"
                                        className="text-xs text-gray-400"
                                    >
                                        {inboundToken.flowRate?.amountEth
                                            ? t("details.fee.value", {
                                                  amount: toFixedFromString(
                                                      (
                                                          Number(
                                                              inboundToken
                                                                  .flowRate
                                                                  ?.amountEth
                                                          ) *
                                                          PROTOCOL_FEE_MULTIPLIER
                                                      ).toString()
                                                  ),
                                                  symbol: inboundToken.token
                                                      ?.symbol,
                                                  period: inboundToken.flowRate
                                                      ?.period,
                                              })
                                            : t("details.fee.default", {
                                                  percentage: 0.5,
                                                  period: inboundToken.flowRate
                                                      ?.period,
                                              })}
                                    </p>
                                </div>
                                <div className="flex justify-between">
                                    <CustomPopover
                                        trigger={
                                            <div className="flex items-center gap-1 rounded-md cursor-pointer">
                                                <p
                                                    data-testid="avg-payout-text"
                                                    className="text-gray-400 text-xs tracking-widest"
                                                >
                                                    {t(
                                                        "details.avgPayout.title"
                                                    )}
                                                </p>
                                                <Icons.info
                                                    data-testid="avg-payout-tooltip"
                                                    className="fill-gray-400"
                                                    size={12}
                                                />
                                            </div>
                                        }
                                        content={
                                            <p
                                                data-testid="avg-payout-tooltip-content"
                                                className="text-gray-400 text-xs tracking-widest"
                                            >
                                                {t("details.avgPayout.tooltip")}
                                            </p>
                                        }
                                    />

                                    <p
                                        data-testid="avg-payout-value"
                                        className="text-xs text-gray-400"
                                    >
                                        {avgLMEFrequency > 0
                                            ? `~${formatDuration({
                                                  minutes: avgLMEFrequency,
                                              })}`
                                            : "--"}
                                    </p>
                                </div>

                                <div className="flex justify-between">
                                    <CustomPopover
                                        trigger={
                                            <div className="flex items-center gap-1 rounded-md cursor-pointer">
                                                <p
                                                    data-testid="reward-text"
                                                    className="text-gray-400 text-xs tracking-widest"
                                                >
                                                    {t("details.reward.title")}
                                                </p>
                                                <Icons.info
                                                    data-testid="reward-tooltip"
                                                    className="fill-gray-400"
                                                    size={12}
                                                />
                                            </div>
                                        }
                                        content={
                                            <p
                                                data-testid="reward-tooltip-content"
                                                className="text-gray-400 text-xs tracking-widest"
                                            >
                                                {t("details.reward.tooltip", {
                                                    symbol: rewardToken.symbol,
                                                })}
                                            </p>
                                        }
                                    />

                                    <p
                                        data-testid="reward-value"
                                        className="text-xs text-gray-400"
                                    >
                                        {tokensSelected && flowRateSelected
                                            ? t("details.reward.value", {
                                                  number: toFixedFromString(
                                                      estimateUserRewardFlowrate(
                                                          torex,
                                                          inboundToken.flowRate
                                                      ).amountEth
                                                  ),
                                                  symbol: rewardToken.symbol,
                                                  period: inboundToken.flowRate
                                                      ?.period,
                                              })
                                            : "--"}
                                    </p>
                                </div>
                                <div className="flex justify-between">
                                    <CustomPopover
                                        trigger={
                                            <div className="flex items-center gap-1 rounded-md cursor-pointer">
                                                <p
                                                    data-testid="stream-ends-text"
                                                    className="text-gray-400 text-xs tracking-widest"
                                                >
                                                    {t(
                                                        "details.streamEnds.title"
                                                    )}
                                                </p>
                                                <Icons.info
                                                    data-testid="stream-ends-tooltip"
                                                    className="fill-gray-400"
                                                    size={12}
                                                />
                                            </div>
                                        }
                                        content={
                                            <p
                                                data-testid="stream-ends-tooltip-content"
                                                className="text-gray-400 text-xs tracking-widest"
                                            >
                                                {t(
                                                    "details.streamEnds.tooltip"
                                                )}
                                            </p>
                                        }
                                    />

                                    <p
                                        data-testid="stream-ends-value"
                                        className="text-xs text-gray-400"
                                    >
                                        {tokensSelected && flowRateSelected
                                            ? calculateStreamEnds(
                                                  (inboundBalance?.token
                                                      .balance ?? 0n) +
                                                      (skipWrapping
                                                          ? 0n
                                                          : amountToWrap),
                                                  (netFlow ?? 0n) -
                                                      getFlowRatePerSecond(
                                                          inboundToken.flowRate
                                                      ) +
                                                      existingFlowRate
                                              )
                                            : "--"}
                                    </p>
                                </div>
                            </AccordionContent>
                        </AccordionItem>
                    </Accordion>
                </div>

                {amountToWrap > 0n && (
                    <div className="flex items-center space-x-2 mt-2">
                        <Switch
                            data-testid="skip-wrapping-button"
                            checked={skipWrapping}
                            onCheckedChange={(checked) =>
                                setSkipWrapping(checked)
                            }
                            id="skip-wrap"
                            className="scale-75 data-[state=unchecked]:bg-gray-400"
                            thumbClassName="data-[state=checked]:bg-brand-main data-[state=unchecked]:bg-gray-500"
                        />
                        <CustomPopover
                            trigger={
                                <div className="flex items-center gap-1 rounded-md cursor-pointer">
                                    <Label htmlFor="skip-wrap">
                                        {t("wrap.skip")}
                                    </Label>
                                    <Icons.info
                                        className="fill-gray-400"
                                        size={12}
                                    />
                                </div>
                            }
                            content={
                                <p className="text-gray-400 text-xs tracking-widest">
                                    {t("wrap.tooltip")}
                                </p>
                            }
                        />
                    </div>
                )}

                {steps.length > 1 && (
                    <Stepper
                        activeStep={activeStep}
                        steps={steps}
                        className="pt-4"
                    />
                )}
            </div>
        </CardSkeleton>
    );
};
